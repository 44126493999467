import React from 'react';


function App() {
  return (
    <div className="app">
      <img src={require('../src/assets/logo.jpg')} alt="Logo" width='25%'/>
      <h4>Aguarde o lançamento de nossa loja!</h4>
    </div>
  );
}

export default App;
